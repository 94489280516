<template>
  <div class="select-store px-3 px-md-0">
    <Card padding="medium" class="select-store__content">
      <Margins>
        <CardHeader
          :header="view ? $t('DEALERS') : $t('PICK_CLOSEST_SHOP')"
          variant="corner-small"
        />

        <InputField
          :placeholder="`${$t('SEARCH')}`"
          name="search"
          type="text"
          v-model="searchStore"
        >
          <template v-slot:suffix>
            <SearchSVG class="icon" />
          </template>
        </InputField>

        <SelectStoreList
          class="margins__double"
          :stores="filteredStores"
          :selectedStore="selectedStore"
          @hover-store="hoveredStore = $event"
          @choose-store="saveOrderDeliveryInfo($event)"
        />
      </Margins>
    </Card>

    <SelectStoreMap
      class="d-none d-md-block"
      :stores="filteredStores"
      :hoveredStore="hoveredStore"
      :view="view"
      @reset-index="hoveredStore = $event"
      @select-store="selectedStore = $event"
      @choose-store="saveOrderDeliveryInfo($event)"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';
import { GET_STORES, SET_BUNDLE_ORDER_DELIVERY_INFO } from '@/types';
import { Card, CardHeader, Margins, InputField } from '@/components';
import { SelectStoreList, SelectStoreMap } from '@/containers/SelectStore';
import { constants } from '@/mixins';
import SearchSVG from '@/assets/images/search.svg';

export default {
  name: 'SelectStore',
  mixins: [constants],
  components: {
    Card,
    CardHeader,
    Margins,
    SelectStoreList,
    SelectStoreMap,
    InputField,
    SearchSVG,
  },
  props: {
    view: Boolean,
  },
  data() {
    return {
      hoveredStore: null,
      selectedStore: null,
      searchStore: '',
    };
  },
  mounted() {
    this.GET_STORES();
  },
  methods: {
    ...mapActions([GET_STORES]),
    ...mapMutations([SET_BUNDLE_ORDER_DELIVERY_INFO]),
    saveOrderDeliveryInfo(store) {
      if (this.view) {
        return false;
      }

      const formData = {
        deliveryType: this.DELIVERY_TYPES.STORE,
        deliveryStoreId: store.id,
      };

      this.SET_BUNDLE_ORDER_DELIVERY_INFO(formData);

      this.$router.push({ name: this.ROUTES.LOAN_APPLICATION.name });
    },
  },
  computed: {
    ...mapState(['stores']),
    filteredStores() {
      return this.stores.filter((store) => {
        return store.name
          .toLowerCase()
          .includes(this.searchStore.toLowerCase());
      });
    },
  },
};
</script>

<style lang="scss">
.select-store {
  border-radius: $border-radius-medium;
  position: relative;

  @include min-width(md) {
    height: 100%;
    min-height: 540px;
    margin-left: 100px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.select-store__content {
  width: 100%;
  position: relative;
  z-index: 1;

  @include min-width(md) {
    width: 360px;
    max-width: 100%;
  }
}
</style>
