<template>
  <div class="select-store-list">
    <ul
      :class="[
        'select-store-list__list',
        {
          'select-store-list__list--fade-top': !isScrolledToListTop,
          'select-store-list__list--fade-bottom': !isScrolledToListBottom,
        },
      ]"
      ref="list"
    >
      <li v-for="(store, index) in stores" :key="index">
        <Button
          variant="text"
          block
          @mouseover.native="hoverStore(index)"
          @focus.native="hoverStore(index)"
          @click="chooseStore(store)"
          :active="selectedStore === index"
          :disabled="!isAvailable(store.id)"
        >
          <template v-slot:icon>
            <MapMarkerSVG class="icon icon--small text-black" />
          </template>
          {{ store.name }}
          <span
            class="button__text-small text-small text-grey"
            v-if="!isAvailable(store.id)"
            >({{ $t('NOT_AVAILABLE') }})</span
          >
        </Button>
      </li>
    </ul>
  </div>
</template>

<script>
import { Button } from '@/components';
import MapMarkerSVG from '@/assets/images/icon-pin.svg';
import debounce from 'lodash/debounce';
import { mapState } from 'vuex';
import { constants } from '@/mixins';
import store from '@/store';

export default {
  name: 'SelectStoreList',
  mixins: [constants],
  components: {
    Button,
    MapMarkerSVG,
  },
  props: {
    selectedStore: Number,
    stores: Array,
  },
  data() {
    return {
      isScrolledToListTop: true,
      isScrolledToListBottom: false,
    };
  },
  mounted() {
    this.handleDebouncedScroll = debounce(this.handleScroll, 100);
    setTimeout(() => this.handleScroll(), 1000);

    this.$refs.list.addEventListener('scroll', this.handleDebouncedScroll, {
      passive: true,
    });
  },
  beforeDestroy() {
    this.$refs.list.removeEventListener('scroll', this.handleDebouncedScroll, {
      passive: true,
    });
  },
  methods: {
    isAvailable(storeId) {
      return !!storeId;
    },
    sortByAvailable(storeA, storeB) {
      const aIsAvailable = this.isAvailable(storeA.id);
      const bIsAvailable = this.isAvailable(storeB.id);
      if (bIsAvailable > aIsAvailable) {
        return 1;
      } else if (bIsAvailable < aIsAvailable) {
        return -1;
      } else {
        return 0;
      }
    },
    handleScroll() {
      const element = this.$refs.list;

      if (element.scrollTop === 0) {
        this.isScrolledToListTop = true;
      } else {
        this.isScrolledToListTop = false;
      }

      if (element.offsetHeight + element.scrollTop === element.scrollHeight) {
        this.isScrolledToListBottom = true;
      } else {
        this.isScrolledToListBottom = false;
      }
    },
    hoverStore(index) {
      this.$emit('hover-store', index);
    },
    chooseStore(store) {
      this.$emit('choose-store', store);
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
    sortedStores() {
      const sortedStores = this.stores;
      return sortedStores.sort(this.sortByAvailable);
    },
  },
};
</script>

<style lang="scss">
.select-store-list {
  position: relative;
}

.select-store-list__list {
  height: 270px;
  overflow-y: auto;
  overflow-x: hidden;
}

.select-store-list__list--fade-top {
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 1;

    @include min-width(md) {
      display: none;
    }
  }
}

.select-store-list__list--fade-bottom {
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 3rem;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 100%
    );
    pointer-events: none;
    z-index: 1;

    @include min-width(md) {
      display: none;
    }
  }
}
</style>
