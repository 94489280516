<template>
  <div class="select-store-map">
    <div class="select-store-map__map">
      <GmapMap
        :center="center || mapConfig.centerPosition"
        :zoom="mapConfig.zoom"
        :options="{
          styles: mapStyles,
        }"
        class="map"
      >
        <GmapMarker
          v-for="(store, index) in stores"
          :key="index"
          :position="{ lat: store.latitude, lng: store.longitude }"
          :clickable="isAvailable(store.id)"
          :title="store.name"
          :icon="getIcon(store.id)"
          :ref="`marker-${index}`"
          @click="openInfoWindow(index, store)"
        />
        <GmapInfoWindow
          :options="infoWindowConfig.options"
          :position="infoWindowConfig.position"
          :opened="infoWindowConfig.open"
          @closeclick="closeInfoWindow"
        />
      </GmapMap>
    </div>
  </div>
</template>

<script>
import { mapStyles } from '@/utils';
import { constants } from '@/mixins';
import { mapState } from 'vuex';

export default {
  name: 'SelectStoreMap',
  mixins: [constants],
  props: {
    hoveredStore: Number,
    stores: Array,
    view: Boolean,
  },
  watch: {
    hoveredStore: function (newIndex, oldIndex) {
      this.stopAnimation(oldIndex);
      this.startAnimation(newIndex);
    },
  },
  data() {
    return {
      center: null,
      infoWindowConfig: {
        options: {
          content: '',
          pixelOffset: {
            width: 0,
            height: -35,
          },
        },
        open: false,
        position: {
          lat: 0,
          lng: 0,
        },
      },
    };
  },
  mounted() {
    document.addEventListener('click', this.handleStoreSelecting);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.handleStoreSelecting);
  },
  methods: {
    handleStoreSelecting(e) {
      const target = e.target;

      if (target.className.includes('js-choose-store')) {
        const store = JSON.parse(target.dataset.store);

        this.chooseStore(store);
      }
    },
    isAvailable(storeId) {
      return !!storeId;
    },
    getIcon(storeId) {
      return this.isAvailable(storeId)
        ? this.getAvailableIcon()
        : this.getOutOfStockIcon();
    },
    getAvailableIcon() {
      return 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMjRweCIgaGVpZ2h0PSIyOHB4IiB2aWV3Qm94PSIwIDAgMTQgMTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDUyLjUgKDY3NDY5KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5JY29uL1BpbjwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxnIGlkPSJJY29uL1BpbiIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9Im1hcHMtYW5kLWZsYWdzIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMC4wMDAwMDAsIDAuMDAwMDAwKSIgZmlsbD0iIzAwMDAwMCIgZmlsbC1ydWxlPSJub256ZXJvIj4KICAgICAgICAgICAgPHBhdGggZD0iTTYuNiwwIEMyLjk2MDc0MDM1LDAgMCwyLjkyNDM3MDc1IDAsNi41MTg4OTA3NiBDMCwxMC45Nzk4MDI0IDUuOTA2MzUzNDQsMTcuNTI4NjgxNSA2LjE1NzgyMTY4LDE3LjgwNTI5MTIgQzYuMzk0MDIwMjksMTguMDY1MTMxNCA2LjgwNjQwNjgzLDE4LjA2NDY3NDQgNy4wNDIxNzgzMiwxNy44MDUyOTEyIEM3LjI5MzY0NjU2LDE3LjUyODY4MTUgMTMuMiwxMC45Nzk4MDI0IDEzLjIsNi41MTg4OTA3NiBDMTMuMTk5OTI4OCwyLjkyNDM3MDc1IDEwLjIzOTIyNDEsMCA2LjYsMCBaIE02LjYsOS43OTg3MjY3OCBDNC43Njg5OTgwNCw5Ljc5ODcyNjc4IDMuMjc5NDA5MTUsOC4zMjc0MDA3NyAzLjI3OTQwOTE1LDYuNTE4ODkwNzYgQzMuMjc5NDA5MTUsNC43MTAzODA3NCA0Ljc2OTAzMzY0LDMuMjM5MDg5ODggNi42LDMuMjM5MDg5ODggQzguNDMwOTY2MzYsMy4yMzkwODk4OCA5LjkyMDU1NTI2LDQuNzEwNDE1OSA5LjkyMDU1NTI2LDYuNTE4OTI1OTEgQzkuOTIwNTU1MjYsOC4zMjc0MzU5MyA4LjQzMDk2NjM2LDkuNzk4NzI2NzggNi42LDkuNzk4NzI2NzggWiIgaWQ9IlNoYXBlIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4K';
    },
    getOutOfStockIcon() {
      return 'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjQiIGhlaWdodD0iMjgiIHZpZXdCb3g9IjAgMCAxNCAxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNi42IDBDMi45NiAwIDAgMi45MjQgMCA2LjUxOWMwIDQuNDYgNS45MDYgMTEuMDEgNi4xNTggMTEuMjg2YS42LjYgMCAwIDAgLjg4NCAwQzcuMjk0IDE3LjUzIDEzLjIgMTAuOTggMTMuMiA2LjUyIDEzLjIgMi45MjQgMTAuMjQgMCA2LjYgMHptMCA5Ljc5OWMtMS44MzEgMC0zLjMyLTEuNDcyLTMuMzItMy4yOCAwLTEuODA5IDEuNDg5LTMuMjggMy4zMi0zLjI4IDEuODMxIDAgMy4zMiAxLjQ3MSAzLjMyIDMuMjggMCAxLjgwOC0xLjQ4OSAzLjI4LTMuMzIgMy4yOHoiIGZpbGw9IiNhNmE2YTYiIGZpbGwtcnVsZT0ibm9uemVybyIvPjwvc3ZnPg==';
    },
    getMarkerObject(index) {
      const markerRef = this.$refs[`marker-${index}`][0];

      if (!markerRef) {
        return false;
      }

      const marker = markerRef.$markerObject;

      if (!marker) {
        return false;
      }

      return marker;
    },
    stopAnimation(index) {
      if (index === null) {
        return false;
      }

      const marker = this.getMarkerObject(index);

      if (!marker) {
        return false;
      }

      marker.setAnimation(null);

      this.$emit('reset-index', null);
    },
    startAnimation(index) {
      if (index === null) {
        return false;
      }

      const marker = this.getMarkerObject(index);

      if (!marker) {
        return false;
      }

      if (marker.animating) {
        this.stopAnimation(index);
      }

      marker.setAnimation(window.google.maps.Animation.BOUNCE);

      setTimeout(() => {
        this.stopAnimation(index);
      }, 2000);
    },
    setCenterPosition(index) {
      const currentStore = this.stores[index];
      this.center = currentStore.position;
    },
    openInfoWindow(index, store) {
      this.selectStore(index);

      const marker = this.$refs[`marker-${index}`][0].$markerObject;

      this.infoWindowConfig.position = marker.position;
      this.infoWindowConfig.options.content = `
        <button
          type="button"
          class="button button--text button--small js-choose-store"
          ${this.view ? 'disabled' : null}
          data-store='${JSON.stringify(store)}'>
            <strong>${store.name}</strong>
            ${
              store.address
                ? `<br />
                <span>${store.address}</span>`
                : ''
            }
        </button>
      `;

      this.infoWindowConfig.open = true;
    },
    closeInfoWindow() {
      this.infoWindowConfig.open = false;

      this.$emit('select-store', null);
    },
    selectStore(index) {
      this.$emit('select-store', index);
    },
    chooseStore(store) {
      this.$emit('choose-store', store);
    },
  },
  computed: {
    ...mapState(['selectedBundle']),
    mapConfig() {
      return this.STORE.mapConfig;
    },
    mapStyles() {
      return mapStyles();
    },
  },
};
</script>

<style lang="scss">
.select-store-map {
  width: 100%;
  position: relative;
  left: auto;
  right: 0;
  bottom: 0;
  top: 0;

  @include min-width(md) {
    position: absolute;
    width: 100vw;
  }
}

.select-store-map__map {
  height: 100%;
  top: 0px;
}

.map {
  height: 100%;
  width: 100%;
}
</style>
